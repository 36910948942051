const fontSize = () => {
  const btn = document.querySelector("#font-size");

  const toggleFontSize = () => {
    btn.classList.toggle("active");
    document.documentElement.classList.toggle("active");
  };

  btn.addEventListener("click", toggleFontSize);
};

export default fontSize;
