const search = () => {
  const btn = document.querySelector("#search");

  const toggleSearch = () => {
    const wrapper = document.querySelector(".search-wrapper");
    btn.classList.toggle("active");
    wrapper.classList.toggle("show");
  };

  btn.addEventListener("click", toggleSearch);
};

export default search;
