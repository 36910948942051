import AOS from "aos";
import "aos/dist/aos.css";

import menu from "./components/menu/index";
import projectsSlider from "./components/sliders/projects-slider";
import more from "./components/more";
import cokkiesInfo from "./components/cookies";

document.addEventListener("DOMContentLoaded", () => {
  menu();
  cokkiesInfo();
  more();
  projectsSlider();
  AOS.init();
});
