const hamburger = () => {
  const hamburger = document.querySelector(".hamburger");

  const toggleMenu = () => {
    const wrapper = hamburger.previousElementSibling.previousElementSibling;
    hamburger.classList.toggle("hamburger--active");
    wrapper.classList.toggle("show");
  };

  hamburger.addEventListener("click", toggleMenu);
};

export default hamburger;
