import header from "./header";
import hamburger from "./hamburger";
import fontSize from "./font-size";
import search from "./search";

const menu = () => {
  if (!document.querySelector("header")) return;
  header();
  hamburger();
  fontSize();
  search();
};

export default menu;
