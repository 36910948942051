import Cookies from "js-cookie";

const cokkiesInfo = () => {
  if (!document.querySelector(".cookies")) return;
  const cookies = document.querySelector(".cookies");

  Cookies.get("cookies") != "yes" ? (cookies.style.display = "block") : ``;

  cookies.addEventListener("click", function() {
    Cookies.set("cookies", "yes", { expires: 30 });
    cookies.style.display = "none";
  });
};

export default cokkiesInfo;
