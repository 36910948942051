const more = () => {
  const btn = document.querySelector(".more-btn");

  const scroll = (e) => {
    e.preventDefault();
    const id = e.currentTarget.getAttribute("href");
    const rwd =
      document.body.clientWidth < 576
        ? 60
        : document.body.clientWidth < 992
        ? 80
        : 126;
    const top = document.querySelector(id).offsetTop - rwd;
    window.scrollTo({
      top,
      behavior: "smooth",
    });
  };
  btn.addEventListener("click", scroll);
};

export default more;
