import { tns } from "tiny-slider/src/tiny-slider";

const projectsSlider = () => {
  if (!document.querySelector(".projects-slider")) return;
  const slider = tns({
    container: ".projects-slider__wrapper",
    controlsContainer: ".projects-slider__nav",
    nav: false,
    items: 1,
    gutter: 15,
    slideBy: 1,
    responsive: {
      576: {
        items: 2,
      },
      992: {
        items: 3,
      },
    },
  });
};

export default projectsSlider;
