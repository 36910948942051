const header = () => {
  const headerScroll = () => {
    const headerEl = document.querySelector("header");
    window.scrollY > 0
      ? headerEl.classList.add("sticky")
      : headerEl.classList.remove("sticky");
  };

  document.addEventListener("scroll", headerScroll);
};
export default header;
